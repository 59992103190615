.pricing-card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding:10px;
    width: 30%;
    margin:10px;
    text-align: center;
}

.pricing-card-one{
    border:1px solid #2c4cdb;
    border-radius: 10px;
    padding:5px;
    width: 40%;
    text-align: center;
}

.pricing-card-heading{
    color:#2c4cdb;
    font-size:18px;
    font-family: Poppins;
}

.pricing-desc-one{
    color:rgb(66, 67, 67);
    font-size: 15px;
}

.heading-two-pricing-card{
    color:#2c4cdb;
    font-size:18px;
    font-family: Poppins;
}

.pricing-main-container{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:10px;
}


.customFont{
    font-family: Poppins;
}

@media  screen and (max-width:564px) {
    .pricing-main-container{
        width:100%;
    }

    .pricing-card-container{
        width:80%;
    }
}