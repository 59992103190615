/* General styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.containerdataset {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header styles */
h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

/* Input field styles */
.datasetinput {
  background-color: #fff;
  border: 1px solid #e0eaf0;
  border-radius: 5px;
  box-shadow: #0000001a 0 1px 3px;
  box-sizing: border-box;
  color: #59707f;
  display: block;
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0;
  padding: 8px 12px 8px 36px;
  transition: border-width .1s,border-color .1s,border-radius .1s,background-image .1s;
  width: 100%;
  height: 36px;
  outline: none;
  position: relative;
  background-image: url(../../../../assets/svg/search.svg);
  background-position: 13px 50%;
  background-repeat: no-repeat no-repeat;
  background-size: 15px;
  margin-bottom: 10px;

}

/* input[type="text"]:focus {
  border-color: #007bff;
} */

/* Table styles */
datasetTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border:none;
  height: auto;
  width: auto;
  border:0px
}

.datasets th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.datasets td{
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #101828;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.datasets th {
  background-color: #f8f9fa;
  color: #1e284c;
  font-weight: bold;
  font-size: 14px;
}

.datasets tr:nth-child(even) {
  background-color: #f9f9f9;
}

.datasets tbody td{
font-size: 14px;
color: #667085;
}


/* Delete button styles */
button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c82333;
}
