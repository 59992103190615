/* src/pages/kpi/index.css */
.container1 {
    padding: 2rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
    height: 85vh;
    justify-content: center;
}

.buttonContainer {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    justify-content: center;
}

.button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.button:hover {
    background-color: #0056b3;
}

.sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-content: center;
    max-width: fit-content;
    margin: 0 auto;
}

.section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.clickable {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.clickable:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.section h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.5rem;
}

.backButton {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #6c757d;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
    transition: background-color 0.2s ease;
}

.backButton:hover {
    background-color: #5a6268;
}

.selected-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

