body{
  padding: 0;
  margin: 0;
}
.app-section{
    width: 100%;
    height: 100vh;
font-family: Inter, sans-serif;
overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    gap: 20px;
.title{
position: absolute;
top: 80px;
margin: auto;
font-size: 2em;
}
    video{
        width: 150%;
        height: 100%;
        object-fit: cover;
    }
    .navbar{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 20;
        border-radius: 5px;
        box-shadow:0px 3px 5px rgba(0, 0, 0,0.2);
    }
}


.explore-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 120px;
    align-items: center;
    .radio-btns{
        align-self: flex-start;
        margin: -10px 20px 0px;
        padding: 0px 140px 0px;
    }

hr{
    width: 80%;
    background-color: rgb(92, 92, 92);
    margin-bottom: 70px;
    margin-top: -10px;
}

.prompts{
    overflow-y: scroll;
    height: 30vh;
    margin-bottom: 20px;
}
.explore-container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .input{
            width: 60%;
            height: 45px;
            font-size: 18px;
            margin:0px 5px;
        }
    }
}


.prompts-section{
    width: 60vw;
display: flex;
flex-direction: column;
justify-content: space-between;

.prompt-detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    }

    
.prompt-container{
    width: 60%;
    background-color: white;
    margin: 5px;
    padding: 8px;
    border-radius: 5px;
    color: black;
   box-shadow: 0px 0.5px 4px rgba($color: #000000, $alpha: 0.2);
    margin-bottom: 20px;
}
}




