.container {
    width: 100vw;
    position: absolute;
    top: 180px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    align-items: center;
    gap: 20px;

    .upload-section {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .upload-container {
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 130px;
        justify-content: space-evenly;
        align-items: center;

        background-color: rgba(255, 255, 255, 0.9);
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-family: Inter, sans-serif;
        color: rgb(20, 106, 255);
        font-size: 1.2em;
        border: dotted rgb(181, 181, 181) 2px;
        cursor: pointer;
        scale: 1;
        transition: 0.2s ease-in;

    }

    .upload-container:hover {
        background-color: rgba(253, 253, 253, 0.95);
        border: dotted rgb(123, 171, 255) 2px;
        scale: 1.1;
        transition: 0.5s ease-out;

        box-shadow: 2px 2px 15px rgba(62, 121, 249, 0.4);
    }



    .csv-files {
        cursor: pointer;
        display: flex;
        background-color: rgba(255, 255, 255, 0.9);
        justify-content: space-between;
        align-items: center;
        // width: 300px;
        transition: 0.2s ease-in;
        font-family: Inter, sans-serif;
        scale: 1;
        border-radius: 5px;
        height: 130px;
        flex-direction: column;
        // color: rgb(20, 106, 255);
        color: black;
        padding: 8px;
        margin: 10px;
        border: solid rgba(181, 181, 181, 0.626) 1.5px;

        img {
            height: 70px;
        }

        .filename {
            margin: 5px 0px;
            font-weight: 500;
            font-size: 1em;
        }
    }

    .csv-files:hover {
        background-color: rgba(253, 253, 253, 0.95);
        scale: 1.1;
        transition: 0.5s ease-out;
        box-shadow: 2px 2px 13px rgba(18, 18, 18, 0.2);

    }

}