.forecast-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;

    .upload-section {
        margin-bottom: 2rem;

        .upload-card {
            padding: 2rem;
            text-align: center;

            h2 {
                margin-bottom: 1.5rem;
                color: #333;
            }

            .upload-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }
        }
    }

    .questions-section {
        h2 {
            margin-bottom: 1.5rem;
            color: #333;
        }

        .questions-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 1.5rem;

            .question-card {
                padding: 1.5rem;
                transition: transform 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                }

                p {
                    margin: 0;
                    color: #444;
                    line-height: 1.5;
                }
            }
        }
    }
}

.explorationSection {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;

    h2 {
        margin-bottom: 1.5rem;
        color: #333;
    }

    .sampleQuestions {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;
        flex-wrap: wrap;
    }

    .answersSection {
        margin-top: 1.5rem;
    }
}

.input-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 15px;
}

.input-card {
    margin-bottom: 20px;
}

.file-info {
    padding: 0 15px 15px;
    margin: 0;
    color: #666;
    font-size: 0.9em;
}

.response-card {
    margin-bottom: 20px;
    padding: 15px;
}

.response-card img {
    max-width: 100%;
    height: auto;
}

.question-card {
    cursor: pointer;
    transition: background-color 0.2s;
}

.question-card:hover {
    background-color: #f5f5f5;
}

.imputed {
    background-color: #fee2e2 !important;
}
