.chat-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
  padding-top: 0px;
  gap: 20px;
}

.recent-chats {
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 20%;
  height: 90vh;
  padding: 20px;
  overflow-y: auto;
}

.recent-chats h3 {
  font-size: 1.2rem;
  color: #2d3748;
  margin-bottom: 16px;
}

.recent-chat-item {
  background: #f9f9f9;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: background 0.3s;
}

.recent-chat-item:hover {
  background: #f1f3f4;
}

.recent-chat-item p {
  margin: 0;
  font-size: 0.9rem;
  color: #4a5568;
}

.chat-window {
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chat-messages .user-message,
.chat-messages .bot-message {
  padding: 12px 16px;
  margin: 0;
  border-radius: 12px;
  max-width: 70%;
  line-height: 1.5;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  animation: fadeIn 0.3s ease-in-out;
}

.bot-message {
  background-color: #f1f3f4;
  color: #1a1a1a;
  align-self: flex-start;
}

.user-message {
  background-color: #4a90e2;
  color: white;
  align-self: flex-end;
}

.chat-input-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  background: #f9f9f9;
  border-radius: 24px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chat-input {
  flex: 1;
  padding: 10px 16px;
  margin-right: 10px;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  outline: none;
  background: transparent;
}

.chat-input:focus {
  border-color: #4a90e2;
}

.file-input {
  display: none;
}

.send-button {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
}

.send-button:hover {
  background-color: #357ab7;
  transform: translateY(-2px);
}

.send-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
  transform: none;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 8px 16px;
  margin-right: 10px;
  flex-grow: 1;
  transition: border-color 0.3s;
}

.input-container:focus-within {
  border-color: #4a90e2;
}

.upload-icon {
  cursor: pointer;
  color: #666;
  margin-right: 8px;
  transition: color 0.3s;
}

.upload-icon:hover {
  color: #4a90e2;
}

.message-type-dropdown {
  padding: 4px 8px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
}

.message-type-dropdown:focus {
  outline: none;
}

.message-type-dropdown:hover {
  color: #4a90e2;
}

.file-name {
  font-size: 0.8rem;
  color: #666;
  margin: 5px 0;
  padding-left: 10px;
}

.spinner-container {
  display: flex;
  justify-content: start;
  margin: 10px 10px;
  color: #4a90e2;
}

.visualization-section {
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 30%;
  height: 90vh;
  padding: 24px;
  overflow-y: auto;
}

.visualization-section h3 {
  font-size: 1.2rem;
  color: #2d3748;
  margin-bottom: 16px;
}

.plot-container {
  width: 100%;
  height: 100%;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}