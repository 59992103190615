.container {
    /* padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
  .heading {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .listItem {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
  }
  